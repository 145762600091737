import App from "next/app";
import React from "react";
import { ApolloProvider } from "react-apollo";
import { StripeProvider } from "react-stripe-elements";
import * as Sentry from "@sentry/browser";
import SnackbarProvider from "react-simple-snackbar";

import { sentryDsn, stripePubKeyDev, stripePubKeyProd } from "../config";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: sentryDsn,
  });
}

import withApolloClient from "../lib/with-apollo-client";
import Page from "../containers/page/page";

import "../styles/main.scss";

class NextApp extends App {
  constructor() {
    super();
    this.state = {
      stripe: null,
      page: 1,
    };
    this.updatePage = this.updatePage.bind(this);
  }

  updatePage() {
    this.setState((prevState) => ({
      page: prevState.page + 1,
    }));
  }
  static async getInitialProps({ Component, router, ctx }) {
    let pageProps = {};

    if (Component.getInitialProps) {
      try {
        pageProps = await Component.getInitialProps(ctx);
      } catch (e) {
        console.log(e);
      }
    }
    pageProps.query = ctx.query;
    return { pageProps };
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV === "production") {
      Sentry.withScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key]);
        });

        Sentry.captureException(error);
      });

      super.componentDidCatch(error, errorInfo);
    }
  }

  componentDidMount() {
    const stripeUrl = "https://js.stripe.com/v3/";
    const stripePubKey =
      process.env.NODE_ENV === "production"
        ? stripePubKeyProd
        : stripePubKeyDev;

    setTimeout(() => {
      if (!document.querySelector("#stripe-js")) {
        const script = document.createElement("script");
        script.async = true;
        script.id = "stripe-js";
        script.onload = () => {
          this.setState({ stripe: window.Stripe(stripePubKey) });
        };
        document.body.appendChild(script);
        script.src = stripeUrl;
      } else if (window.Stripe) {
        this.setState({ stripe: window.Stripe(stripePubKey) });
      }
    }, 5000);

    if (process.env.NODE_ENV === "production" && process.browser) {
      //hotjar.initialize(1419835, 6);
    }

    if (process.browser) {
      this.checkBrowserCSSSupports();
    }
  }

  checkBrowserCSSSupports() {
    const supports = function (prop) {
      const div = document.createElement("div");
      const vendors = "Khtml Ms O Moz Webkit".split(" ");
      let len = vendors.length;

      if (prop in div.style) return true;

      prop = prop.replace(/^[a-z]/, function (val) {
        return val.toUpperCase();
      });

      while (len--) {
        if (vendors[len] + prop in div.style) {
          // browser supports box-shadow. Do what you need.
          // Or use a bang (!) to test if the browser doesn't.
          return true;
        }
      }
      return false;
    };

    if (!supports("grid-gap")) {
      document.body.classList.add("css-fallback--grid");
    }
  }

  render() {
    const { page } = this.state;
    const { Component, pageProps, apolloClient, router } = this.props;
    const propsWithClient = {
      ...pageProps,
      client: apolloClient,
      page,
      updatePage: this.updatePage,
    };

    return (
      <>
        <ApolloProvider client={apolloClient}>
          <StripeProvider stripe={this.state.stripe}>
            <SnackbarProvider>
              <Page route={router.route}>
                <Component {...propsWithClient} />
              </Page>
            </SnackbarProvider>
          </StripeProvider>
        </ApolloProvider>
      </>
    );
  }
}

export default withApolloClient(NextApp);
