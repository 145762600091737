import React, { PureComponent } from "react";
import classNames from "classnames";
import { debounce } from "debounce";
import { Query } from "react-apollo";

import { LOCAL_UI_QUERY } from "../../apollo-state/graphql/queries";

import RoundButton from "../round-button/round-button";

import toggleBodyScroll from "../../utils/toggle-body-scroll";

import "./page-popup.scss";

function pagePopupHoc(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <Query query={LOCAL_UI_QUERY}>
          {(uiState) => (
            <WrappedComponent uiState={uiState.data} {...this.props} />
          )}
        </Query>
      );
    }
  };
}

class PagePopup extends PureComponent {
  constructor() {
    super();
    this.state = {
      maxHeight: "100%",
    };
    this.popupContentRef = React.createRef();
  }

  setMaxHeight = () => {
    const { innerHeight } = window;
    this.setState({
      maxHeight: `${innerHeight - 80}px`,
    });
  };

  componentDidMount() {
    toggleBodyScroll("", "", "ON");
    this.setMaxHeight();
    window.addEventListener("resize", debounce(this.setMaxHeight, 50));
  }

  componentWillUnmount() {
    if (this.props.uiStateName) {
      toggleBodyScroll(this.props.uiState.ui, this.props.uiStateName);
    } else {
      toggleBodyScroll("", "", "OFF"); // only for the additional details one
    }
    window.removeEventListener("resize", debounce(this.setMaxHeight, 50));
  }

  scrollTopOfPopup = () => {
    this.popupContentRef.current.scrollTo(0, 0);
  };

  render() {
    const { togglePopup, modifierClass } = this.props;
    const { maxHeight } = this.state;

    return (
      <div
        className={classNames("page-popup", {
          [`page-popup--${modifierClass}`]: modifierClass,
        })}
      >
        <div className="page-popup__frame">
          <div className="page-popup__flex">
            <div className="page-popup__outer" style={{ maxHeight }}>
              <RoundButton onClick={togglePopup} />
              <div className="page-popup__inner">
                <div className="page-popup__content" ref={this.popupContentRef}>
                  {React.cloneElement(this.props.children, {
                    scrollTopOfPopup: this.scrollTopOfPopup,
                    togglePopup,
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <button className="page-popup__close" onClick={togglePopup} /> */}
      </div>
    );
  }
}

PagePopup.defaultProps = {
  modifierClass: undefined,
};

export default pagePopupHoc(PagePopup);
